<template>
    <div>
        <AlertBrandMusic v-if="isAlertBrandMusic" @closeModal="isAlertBrandMusic = false" />
        <MusicAlert v-if="musicAlertStatus" @closeAlert="musicAlertStatus = false" />
        <ReleaseAlert v-if="isReleaseAlert" @closeModal="isReleaseAlert = false" />
        <SetTimeBrandSound v-if="isSetTime" @closeModal="isSetTime = false" @getList="$emit('getList')" :musicInfo="musicInfo" />
        <div class="brand_modal modal1">
            <div class="brand_modal_box modal_box">
                <div class="brand_modal_title modal_title">
                    <h2>브랜드 음원 설정</h2>
                    <div class="modal_close_btn" @click="$emit('closeBrandMusic')">
                        <img src="/media/images/icon_close_24.svg" alt="" />
                    </div>
                </div>
                <div class="table_top">
                    <table class="brand_table">
                        <thead>
                            <tr>
                                <th>브랜드 음원명</th>
                                <th>시간설정</th>
                                <th>재생여부</th>
                                <th>듣기</th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div class="brand_scroll_body">
                    <table class="brand_table">
                        <tbody>
                            <template v-for="(item, index) in brandMusic">
                                <tr :key="index">
                                    <td :class="{ common_dim: item.spaceId == null }">
                                        <div :class="{ common_icon: item.spaceId == null }" @click="isAlertBrandMusic = true">
                                            <div v-if="item.spaceId == null">
                                                <p>공통</p>
                                            </div>
                                            <p class="music_title" v-html="item.brandMusicNm" />
                                        </div>
                                    </td>
                                    <td class="select-time" :class="{ common_dim: item.spaceId == null }">
                                        <div class="select-box-area" v-if="item.playType === 'R'" @mouseover="showIcon($event, item)" @mouseleave="hideIcon($event)" @click="timeSetting(item)">
                                            <span class="play_type_r">반복</span><span>{{ item.startTime }}부터~{{ item.endTime }}까지</span><span>{{ item.repeatTime }}시간마다</span><img src="/media/img/icon_update.svg" alt="" />
                                        </div>
                                        <div class="select-box-area" v-else-if="item.playType === 'S'" @mouseover="showIcon($event, item)" @mouseleave="hideIcon($event)" @click="timeSetting(item)">
                                            <span class="play_type_s">지정</span><span>{{ item.selectTime }}</span
                                            ><img src="/media/img/icon_update.svg" alt="" />
                                        </div>
                                        <div v-else><button @click="timeSetting(item)">설정하기</button></div>
                                    </td>
                                    <td :class="{ common_dim: item.spaceId == null }">
                                        <div class="alarm_box">
                                            <span :class="{ active1: item.playAt === 'Y' }" @click="updateBrandMusicPlayAt(item)"></span>
                                            <span class="move" :class="{ active1_1: item.playAt === 'Y' }" @click="updateBrandMusicPlayAt(item)"></span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="pre_play">
                                            <img v-bind:id="'img' + index" :src="activeIndex === index ? '/media/img/brand_Pause.svg' : '/media/img/brand_Play.svg'" alt="" @click="onPlay(item.brandMusicId, index)" />
                                            <div class="sound_updown">
                                                <span hidden></span>
                                                <span v-bind:style="{ width: $store.state.playNav + '%' }" hidden></span>
                                                <input v-bind:id="'seekslider' + index" type="range" min="0" max="100" step="1" value="0" hidden />
                                                <video v-bind:id="'bVideo' + index" width="100%" height="1" hidden playsinline></video>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
                <div class="brand_bottom"></div>
            </div>
        </div>
    </div>
</template>

<script>
import AlertBrandMusic from '@/components/modal/Player/AlertBrandMusic.vue';
import ReleaseAlert from '@/components/modal/Player/releaseAlert.vue';
import MusicAlert from '@/components/modal/Space/PreferMusicAlert.vue';
import SetTimeBrandSound from '@/components/modal/buildingSpace/setTime.vue';
import { defineComponent } from '@vue/composition-api';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import Hls from 'hls.js';
import $ from 'jquery';

export default defineComponent({
  data () {
    return {
      musicAlertStatus: false,
      isSetTime: false,
      isAlertBrandMusic: false,
      isReleaseAlert: false,
      selectTime: 'Y',
      repeatTime: 'N',
      musicInfo: {
        brandMusicId: String,
        fileName: String,
        soundTitle: String,
        mySoundDetail: {
          brandSpaceMusicId: String,
          selectTime: '09:00',
          repeatTime: '1',
          startTime: '09:00',
          playType: String
        }
      },
      playUrl: null,
      config: {
        enableWorker: true,
        lowLatencyMode: true,
        backBufferLength: 90
      },
      activeIndex: -1
    };
  },
  props: {
    brandMusic: Array
  },
  components: {
    MusicAlert,
    SetTimeBrandSound,
    AlertBrandMusic,
    ReleaseAlert
  },
  methods: {
    showIcon (e, music) {
      if (music.spaceId != null) $(e.currentTarget).addClass('on');
    },
    hideIcon (e) {
      $(e.currentTarget).removeClass('on');
    },
    timeSetting (music) {
      // 지정/반복 시간 설정 팝업
      if (music.spaceId == null) {
        this.isAlertBrandMusic = true;
      } else {
        this.musicInfo.brandMusicId = music.brandMusicId;
        this.musicInfo.mySoundDetail.playType = music.playType;
        this.musicInfo.mySoundDetail.selectTime = music.selectTime;
        this.musicInfo.mySoundDetail.repeatTime = music.repeatTime;
        this.musicInfo.mySoundDetail.startTime = music.startTime;
        this.musicInfo.mySoundDetail.endTime = music.endTime;
        this.isSetTime = true;
      }
    },
    endBrandVideoEvent () {
      this.activeIndex = -1;
    },
    playMuic (url, index) {
      const bVideo = document.querySelector(`#bVideo${index}`);
      const tg = window.location.host.indexOf('localhost') !== -1 ? 'http://13.209.152.125:10084/stream/getMusic?' + url : '/stream/getMusic?' + url;
      if (Hls.isSupported()) {
        let hls = null;
        if (hls != null) hls.destroy(); // removed all listeners
        hls = new Hls(this.config);
        hls.loadSource(tg);
        hls.attachMedia(bVideo);
        bVideo.play();
      }
    },
    onPlay (url, index) {
      if (!this.$store.state.isPlay) {
        const bVideo = document.querySelector(`#bVideo${index}`);
        bVideo.removeEventListener('ended', this.endBrandVideoEvent);
        bVideo.addEventListener('ended', this.endBrandVideoEvent);
        if (this.activeIndex === index) {
          if (bVideo.paused) {
            this.activeIndex = index;
            this.playMuic(url, index);
          } else {
            this.activeIndex = -1;
            bVideo.pause();
          }
        } else {
          document.querySelectorAll('video[id*="bVideo"]').forEach((item) => item.pause());
          this.activeIndex = index;
          this.playMuic(url, index);
        }
      } else {
        this.musicAlertStatus = true;
      }
    },
    updateBrandMusicPlayAt (music) {
      if (music.spaceId == null) {
        this.isAlertBrandMusic = true;
      } else {
        if (music.playAt === 'N' && music.playType === null) {
          this.isReleaseAlert = true;
        } else {
          music.playAt === 'Y' ? (music.playAt = 'N') : (music.playAt = 'Y');
          const brandMusicId = music.brandMusicId;
          const spaceId = VueCookies.get('spaceId');
          const aToken = VueCookies.get('aToken');
          const headers = { 'X-AUTH-TOKEN': aToken };

          axios
            .post('/api/player/updateBrandSpaceMusicPlayAt', { spaceId, brandMusicId }, { headers })
            .then((res) => {
              if (res.data.resultCd === '0000') this.$emit('getList');
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  }
});
</script>
<style scoped>
.select-box-dropDown p {
  margin: 0;
}

.modal {
  display: block;
}

.brand_modal {
  z-index: 55;
}

.brand_table thead tr th .select-box::after {
  background: none;
}

.disabled {
  opacity: 0.3;
}

input[type='range']:focus {
  outline: none;
  box-shadow: none;
}

/* .brand_table tbody tr td .sound_updown input[type=range] {position:absolute; padding: 0; width:100%; -webkit-appearance: none; background-color: transparent; height: 3px; outline: none; cursor: pointer; margin-bottom: 14px;} */
input[type='range'] {
  position: absolute;
  padding: 0;
  width: 100%;
  -webkit-appearance: none;
  background-color: #ffffff;
  height: 3px;
  outline: none;
  cursor: pointer;
  margin-bottom: 14px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #ffffff;
  cursor: pointer;
  height: 14px;
  width: 14px;
  border-radius: 35px;
}

/* 3.23 공통 브랜드 음원 추가로 인한 CSS 수정 */
.brand_modal_box {
  padding: 32px;
}

.brand_modal_box .brand_modal_title {
  padding-right: 0;
}

.brand_modal_box .table_top {
  padding-right: 0;
}

.brand_modal_box .table_top .brand_table thead tr {
  display: flex;
  align-content: center;
}

.brand_modal_box .table_top .brand_table thead tr th:nth-child(1) {
  width: 40%;
}

.brand_modal_box .table_top .brand_table thead tr th:nth-child(2) {
  width: 35%;
}

.brand_modal_box .table_top .brand_table thead tr th:nth-child(3) {
  width: 15%;
}

.brand_modal_box .table_top .brand_table thead tr th:nth-child(4) {
  width: 10%;
}

.brand_modal_box .brand_scroll_body {
  height: 400px;
  padding: 0;
}

.brand_modal_box .brand_scroll_body .brand_table tbody tr {
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: space-between;
}

.brand_modal_box .brand_scroll_body .brand_table tbody tr td:nth-child(1) {
  width: 40%;
}

.brand_modal_box .brand_scroll_body .brand_table tbody tr td:nth-child(1) p.music_title {
  width: calc(100% - 61px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.brand_modal_box .brand_scroll_body .brand_table tbody tr td:nth-child(2) {
  display: flex;
  align-content: center;
  justify-content: space-between;
  width: 35%;
}

.brand_modal_box .brand_scroll_body .brand_table tbody tr td .select-box {
  float: none;
  display: flex;
  align-items: center;
  width: 140px;
  padding: 10px 16px;
  margin: 0;
  background: #3b3b3b;
}

.brand_modal_box .brand_scroll_body .brand_table tbody tr td:nth-child(3) {
  width: 15%;
}

.brand_modal_box .brand_scroll_body .brand_table tbody tr td:nth-child(4) {
  width: 10%;
}

/* 3.23 */
/* 3.30 브랜드 음원 설정 UI 변경*/
.brand_table tbody tr td:nth-child(1) div {
  pointer-events: none;
}

.brand_table tbody tr td:nth-child(1) div.common_icon {
  cursor: pointer;
  pointer-events: auto;
}

.brand_table tbody tr td.select-time {
  text-align: center;
}

.brand_table tbody tr td.select-time .select-box-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  cursor: pointer;
}

.brand_table tbody tr td.select-time div {
  margin: 0 auto;
}

.brand_table tbody tr td.select-time button {
  width: 66px;
  height: 28px;
  padding: 4px 6px;
  background: hsla(0, 0%, 100%, 0.08);
  border: 1px solid hsla(0, 0%, 100%, 0.4);
  border-radius: 4px;
  color: hsla(0, 0%, 100%, 0.87);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.5px;
}

.select-box-area .play_type_r {
  background: rgba(175, 87, 255, 0.16);
  border-radius: 100px;
  padding: 2px 4px;
  margin-right: 8px;
  color: #af57ff;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
}

.select-box-area .play_type_s {
  background: rgba(255, 158, 45, 0.16);
  border-radius: 100px;
  padding: 2px 4px;
  margin-right: 8px;
  color: #ff9e2d;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
}

.select-box-area span:nth-child(2),
.select-box-area span:nth-child(3) {
  color: #fff;
  padding: 2px 4px;
  margin-right: 6px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.brand_table tbody tr td .select-box-area:hover {
  background: rgba(255, 255, 255, 0.04);
}

.brand_table tbody tr td .select-box-area img {
  position: absolute;
  left: 80%;
  bottom: 40%;
  display: none;
}

.brand_table tbody tr td .select-box-area.on {
  border-radius: 0;
}

.brand_table tbody tr td .select-box-area.on img {
  position: absolute;
  left: 90%;
  bottom: 40%;
  display: block;
}

.brand_table tbody tr td.common_dim {
  opacity: 0.4;
}

.brand_table tbody tr td.common_dim .select-box-area:hover {
  background: transparent;
}

.pre_play img {
  width: 16px;
  height: 20px;
  margin: 0 auto;
  cursor: pointer;
}
</style>
